<template>
  <c-x-payment-layout>
    <div class="Checkout has-text-sans">
      <heading
        :serif="false"
        color="black"
        size="2"
        class="m-b-xl"
      >
        {{ $t('pages.cx.checkout.headline') }}
      </heading>
      <div class="columns">
        <div class="column is-8">
          <div class="border-grey-light p-l-l p-r-l p-v-xl">
            <stripe-card-manager
              ref="stripeCardManager"
              :user="currentUser"
              :card="activeCard"
              :is-replacing-card.sync="isReplacingCard"
            />
          </div>
        </div>
        <div class="column is-4">
          <c-x-cart interval-picker-disabled>
            <v-button
              :loading="isProcessing"
              class="is-fullwidth is-large is-primary m-b-xxl m-t-l"
              data-testid="PurchaseNewPlanButton"
              @click="purchaseSubscription"
            >
              {{ $t('pages.cx.checkout.purchase') }}
            </v-button>
            <div class="has-text-centered">
              <router-link
                :to="{ name: 'cx.manage-subscription' }"
                class="has-text-primary"
              >
                <v-icon type="angle-left" />
                {{ $t('pages.cx.checkout.change_plan') }}
              </router-link>
            </div>
          </c-x-cart>
        </div>
      </div>
    </div>
  </c-x-payment-layout>
</template>

<script>
import store from '@/store'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import StripeCardManager from '@/components/billing/StripeCardManager'
import { BillingApiService } from '@/services/api/BillingApiService'

import CXCart from '@/components/cx/payment/CXCart'
import CXPaymentLayout from '@/router/layouts/CXPaymentLayout'
import { createPaymentPlanCommitmentForm } from '@/models/PaymentPlanCommitment'

/**
 * @module Checkout
 */
export default {
  name: 'Checkout',

  components: { StripeCardManager, CXCart, CXPaymentLayout },

  data () {
    return {
      isProcessing: false,
      isReplacingCard: false,

      /** @type PaymentPlanCommitmentForm */
      form: createPaymentPlanCommitmentForm()
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  beforeRouteEnter (to, from, next) {
    if (!store.getters['cx/payment/currentPlanPrice']) return next({ name: 'cx.pricing' })
    next()
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'activeCard',
      'firstOrganisation',
      'isFreemiumUser'
    ]),

    ...mapState('cx/payment', ['users', 'couponId']),
    ...mapGetters('cx/payment', ['getCurrentPlanCommitment'])
  },

  mounted () {
    this.form.merge({
      plan: this.getCurrentPlanCommitment.slug,
      licenses: this.users,
      coupon: this.couponId
    })
  },

  methods: {
    ...mapActions(['fetchUser']),
    ...mapMutations('cx/payment', ['CLEAR_COUPON']),

    async purchaseSubscription () {
      if (this.isProcessing) return
      this.isProcessing = true

      // reach into the element and collect data if has no active card or is replacing an old one
      if (!this.activeCard || this.isReplacingCard) {
        const response = await this.$refs.stripeCardManager.getToken()
        if (response.error) {
          this.$notify.error(this.$t('errors.credit_card_errors'))
          this.isProcessing = false
          return
        }
        const paymentMethod = response.setupIntent.payment_method

        await BillingApiService.updateCard(this.firstOrganisation.id, {
          payment_method_id: paymentMethod
        })

        // TODO (Bruno): Determine if we should use the API to perform the attachment or not (it's possible again)
        // this.form.payment_method_id = paymentMethod
      }

      try {
        this.$v.form.$touch()
        if (this.$v.form.$error) return

        await this.form.submit(payload => BillingApiService.upgrade(this.firstOrganisation.id, payload))

        this.CLEAR_COUPON()

        /* Re-fetch the user and permissions and everything */
        await this.fetchUser({
          forcePermissions: true
        })

        this.$notify.success(this.$t('success.subscription_changed'))

        this.isReplacingCard = false

        this.$router.push({ name: 'dashboard.dashboard' })
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>
